import Vue from 'vue'
import Vuex from 'vuex'
import Storage from '@/utils/storage'
import Constants from '@/constants'
import UserApi from '@/api/userApi'
import OSSUploader from '@/utils/OSSUploader'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userToken: Storage.getItem(Constants.USER_TOKEN) || null,
		userInfo: Storage.getItem(Constants.USER_INFO) || null,

		fileMap: {}, // 图片缓存
		videoMap: {}, // 视频文件缓存
		allowedVideoReg: /mp4$/, // 支持的视频的格式
		allowedImagesReg: /(jpg|jpeg|bmp|png|gif)$/, // 支持的图片的格式
	},

	mutations: {
		setFileMap(state, v) {
			state.fileMap = v
		},

		setVideoMap(state, v) {
			state.fileMap = v
		},

		setUserToken(state, v) {
			state.userToken = v
			Storage.setItem(Constants.USER_TOKEN, v)
		},

		setUserInfo(state, v) {
			if (!v){
				UserApi.getInfo({
					success: res => {
						state.userInfo = res.data.content
						Storage.setItem(Constants.USER_INFO, v)
					}
				})
			} else {
				state.userInfo = v
				Storage.setItem(Constants.USER_INFO, v)
			}
		},

		logout(state, callback) {
			UserApi.logout({
				success: res => {
					state.userToken = null
					state.userInfo = null
					Storage.clear()
					typeof callback === 'function' && callback(res)
				}
			})
		},

		// 获取素材封面的访问授权及缓存图片信息
		getMaterialsCover(state, {materials, callback}) {
			materials.forEach(v => {
				if (!state.fileMap[v.id]) {
					state.fileMap[v.id] = {authed: false, file: null}
					!((materialId, cover, callback) => {
						if (cover) {
							OSSUploader.getSign(cover, res => {
								state.fileMap[materialId].authed = true
								state.fileMap[materialId].file = res.data.content
								typeof callback === 'function' && callback()
							}, state.allowedVideoReg.test(cover))
						}
					})(v.id, v.cover, callback)
				}
				return v
			})
		},
	},

	getters: {
		userToken: state => state.userToken,
		fileMap: state => state.fileMap,
		videoMap: state => state.videoMap,
	}
})
