import BaseApi from './baseApi'

let userApi = Object.create(BaseApi, {
	baseUrl: {
		value: '/admin/user'
	},
	logout: {
		value: function(params) {
			return BaseApi.request({url: '/logout', method: 'POST'}, params)
		},
		enumerable: true
	},

	getInfo: {
		value: function(params) {
			return BaseApi.request({url: '/user/info', method: 'GET'}, params)
		},
		enumerable: true
	},

	changePwd: {
		value: function(params) {
			return BaseApi.request({url: '/changePwd', data: params.data, method: 'POST'}, params)
		},
		enumerable: true
	},

	bandUser: {
		value: function(id, type) {
			return BaseApi.request({url: this.baseUrl+'/freeze/'+id+'?type='+type, method: 'POST'}, {})
		},
		enumerable: true
	}
})

export default userApi
