import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			component: resolve => require(['../views/login.vue'], resolve)
		},

		{
			path: '/admin',
			name: 'admin',
			meta: {perm: true},
			component: resolve => require(['../views/admin/base.vue'], resolve),
			children: [
				{path: '/admin/dashboard', meta: {perm: true}, component: resolve => require(['../views/admin/dashboard.vue'], resolve)},
				{path: '/admin/banner', meta: {perm: true}, component: resolve => require(['../views/admin/banner.vue'], resolve)},
				{path: '/admin/users', meta: {perm: true}, component: resolve => require(['../views/admin/users.vue'], resolve)},
				{path: '/admin/footballTeam', meta: {perm: true}, component: resolve => require(['../views/admin/footballTeam.vue'], resolve)},
				{path: '/admin/ftMatch', meta: {perm: true}, component: resolve => require(['../views/admin/ftMatch.vue'], resolve)},
				{path: '/admin/ftMatchEditor', meta: {perm: true}, component: resolve => require(['../views/admin/ftMatchEditor.vue'], resolve)},
				{path: '/admin/ftMatchPredict', meta: {perm: true}, component: resolve => require(['../views/admin/ftMatchPredict.vue'], resolve)},
				{path: '/admin/matchGroup', meta: {perm: true}, component: resolve => require(['../views/admin/matchGroup.vue'], resolve)},
				{path: '/admin/news', meta: {perm: true}, component: resolve => require(['../views/admin/news.vue'], resolve)},
				{path: '/admin/msg', meta: {perm: true}, component: resolve => require(['../views/admin/msg.vue'], resolve)},
				{path: '/admin/newsEditor', meta: {perm: true}, component: resolve => require(['../views/admin/newsEditor.vue'], resolve)},
				{path: '/admin/lotteryShop', meta: {perm: true}, component: resolve => require(['../views/admin/lotteryShop.vue'], resolve)},
				{path: '/admin/sysConfig', meta: {perm: true}, component: resolve => require(['../views/admin/sysConfig.vue'], resolve)},
			]
		}
	]
})
router.beforeEach((to, from, next) => {
	let permission = true
	if (to.matched[0] && typeof to.matched[0].meta.permission === 'function') {
		permission = to.matched[0].meta.permission()
	}
	if (permission == true) {
		if (typeof to.meta.permission === 'function') {
			permission = to.meta.permission()
		}
	}
	
	if (to.meta.perm && !store.getters.userToken){
		next(from)
	} else {
		next()
	}

	// if (typeof permission === 'boolean'){
	// 	if (permission) {
	// 		next()
	// 	} else {
	// 		next(from)
	// 	}
	// } else if (typeof permission === 'object') {
	// 	if (permission.allow) {
	// 		next()
	// 	} else {
	// 		if (permission.to) {
	// 			next({
	// 				path: permission.to
	// 			})
	// 		} else {
	// 			next(from)
	// 		}
	// 	}
	// }
})
export default router
