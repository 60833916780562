import BaseApi from './baseApi'

let api = Object.create(BaseApi, {
	sts: {
		value: function(params) {
			BaseApi.request({url: this.baseUrl + '/sts'}, params)
		},
		enumerable: true
	},

	auth: {
		value: function(params) {
			BaseApi.request({url: this.baseUrl + '/auth', params: params.data}, params)
		},
		enumerable: true
	}
})
api.baseUrl = '/ali'

export default api
