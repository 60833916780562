import AliOSSApi from '@/api/aliOSSApi'

const OSS = require('ali-oss');
const REGION = 'oss-cn-shenzhen'
const BUCKET = 'hwsj2018'
let OSSClient = null
let STSToken = null
const FOLDER = 'ads_pub/'
const EXPIRED = 3500 // token有效期，3500秒
let getTokenTime = 0 // 获取token的时间

export default {
	// 上传文件到oss，
	// params 参数： 
	// 		file：file对象，
	// 		filename:文件名，相对于'ad/huaweishijie/'目录的文件名全称，
	// 		progress：上传进度回调函数， 
	// 		partSize：文件切片大小，
	// 		success：上传成功的回调
	upload(params) {
	  	const key = FOLDER + params.filename;
	  	const options = {
		    progress: params.progress,
		    partSize: params.partSize || 100 * 1024
	  	};

	  	this.getClient(client => {
	  		client.multipartUpload(key, params.file, options).then( (res) => {
			    typeof params.success === 'function' && params.success(res)
		  	}).catch((err) => {
			    if (client && client.isCancel()) {
			      console.log('stop-upload!');
			    } else {
			      console.error(err);
			    }
		  	});
	  	})
	},

	// 删除文件，
	// filename：相对于bucket根目录的完整文件名
	// callback：删除成功的回调
	// failure：失败后的回调
	delete(filename, callback, failure) {
		this.getClient(client => {
			filename = filename.replace('http://hwsj2018.oss-cn-shenzhen.aliyuncs.com/', '')
			client.delete(filename).then(res => {
				typeof callback === 'function' && callback(res)
			}).catch(res => {
				typeof failure === 'function' && failure(res)
			});
		})
	},

	getClient(callback) {
		if (!OSSClient || new Date().getTime()/1000-getTokenTime > EXPIRED) {
			AliOSSApi.sts({
				success: res => {
					getTokenTime = new Date().getTime()/1000
					STSToken = res.data.content
					OSSClient = new OSS({
					  region: REGION,
					  accessKeyId: res.data.content.accessKeyId,
					  accessKeySecret: res.data.content.accessKeySecret,
					  stsToken: res.data.content.securityToken,
					  bucket: BUCKET
					});
					typeof callback === 'function' && callback(OSSClient)
				}
			})
		} else {
			typeof callback === 'function' && callback(OSSClient)
		}
	},

	// 获取访问授权
	// isVideoCover：是否取视频第一帧
	getSign(filename, callback, isVideoCover) {
		filename = filename.replace('http://hwsj2018.oss-cn-shenzhen.aliyuncs.com/', '')
		this.getClient(client => {
			this.getAuth(filename, callback, isVideoCover)
		})
	},

	getAuth(filename, callback, isVideoCover) {
		AliOSSApi.auth({
			data: {
				file: filename, 
				stsToken: STSToken.securityToken,
				stsAccessKey: STSToken.accessKeyId,
				stsAccessSecret: STSToken.accessKeySecret,
				cover: isVideoCover
			},
			success: res => {
				typeof callback === 'function' && callback(res)
			}
		})
	}
}